import clsx from 'clsx'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {register} from './core/_requests'
import {toast} from 'react-toastify'

interface CreateAccount {
  businessName: string
  governmentId: string
  countryPublicKey: string
  firstName: string
  lastName: string
  userGovernmentId: string
  phone: string
  email: string
  password: string
  confirmPassword: string
}

type Props = {
  changeValue: (valueToChange: keyof CreateAccount, data: any) => void
  createAccountValues: any
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
}

const ThirdStep: React.FC<Props> = ({
  changeValue,
  createAccountValues,
  setCurrentStep,
  currentStep,
}) => {
  const intl = useIntl()

  function verifyLowerAndUpperCase(value: string) {
    var haveLower = false
    var haveUpper = false

    for (var i = 0; i < value.length; i++) {
      var caractere = value.charAt(i)
      if (caractere === caractere.toLowerCase()) {
        haveLower = true
      } else if (caractere === caractere.toUpperCase()) {
        haveUpper = true
      }
    }

    return haveLower && haveUpper
  }

  function verifyLettersAndNumber(value: string) {
    var haveLetters = false
    var haveNumbers = false

    for (var i = 0; i < value.length; i++) {
      var caractere = value.charAt(i)
      if (/[a-zA-Z]/.test(caractere)) {
        haveLetters = true
      } else if (/[0-9]/.test(caractere)) {
        haveNumbers = true
      }
    }

    return haveLetters && haveNumbers
  }

  function verifyMinLength(value: string) {
    return value.length >= 8
  }

  function verifySpecialChar(value: string) {
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~`@!#$%^&*+=\-[\]\\';,/{}|\\":<>().?])/
    return regex.test(value)
  }

  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const pdfURL = 'https://cdn2.safetyhub.io/safetyhub/ToU/ToU%20SafetyHub%20Pt_Br.pdf'

  const [showInfoModal, setShowInfoModal] = useState(false)

  const handleSubmit = async () => {
    const data = await register(createAccountValues)

    if (data.httpStatus === '201') {
      toast.success(intl.formatMessage({id: 'AUTH.REGISTER.SUBMIT.SUCCESS'}))
      navigate('/auth/login')
    } else {
      setErrorMessage(data?.description.email)
      toast.error(intl.formatMessage({id: 'TOAST.ERROR.CREATE'}))
    }
  }

  return (
    <>
      {showInfoModal && (
        <>
          <Modal
            id='kt_modal_terms_and_conditions'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-800px h-auto'
            show={showInfoModal}
            keyboard={false}
            backdrop='static'
            className='w-100 h-100 '
          >
            <div className='container-xxl px-5 py-5'>
              <div className='modal-header py-2 d-flex flex-column justify-content-center border-0'>
                <button
                  onClick={() => setShowInfoModal(false)}
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                ></button>
                <h1 className='text-dark mb-3 display-4'>
                  {intl.formatMessage({
                    id: 'AUTH.REGISTER_ACCOUNT.HOW_WE_USE_YOUR_DATA.TEXT',
                  })}
                </h1>

                <div className='d-flex flex-column justify-content-start align-items-start'>
                  <div className='p-6 d-flex flex-column gap-2'>
                    <h1>{intl.formatMessage({id: 'PROFILE.FORM.LABEL.EMAIL'})}</h1>
                    <p>{intl.formatMessage({id: 'AUTH.REGISTER_ACCOUNT.FIST_STEP.EMAIL.TEXT'})}</p>
                    <hr className='hr' />
                  </div>

                  <div className='p-6 d-flex flex-column gap-2'>
                    <h1>{intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD'})}</h1>
                    <p>
                      {intl.formatMessage({id: 'AUTH.REGISTER_ACCOUNT.FIST_STEP.PASSWORD.TEXT'})}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}

      {showModal && (
        <>
          <Modal
            id='kt_modal_terms_and_conditions'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-800px h-auto'
            show={showModal}
            keyboard={false}
            backdrop='static'
            className='w-100 h-100 '
          >
            <div className='container-xxl px-5 py-5'>
              <div className='modal-header py-2 d-flex flex-column justify-content-center border-0'>
                <h1 className='text-dark mb-3 display-4'>
                  {intl.formatMessage({id: 'AUTH.REGISTER.TERM.TITLE'})}
                </h1>
                <h1>{isAccepted}</h1>

                <div className='overflow-auto' style={{height: '350px', width: '100%'}}>
                  <p className='text-center'>
                    <iframe
                      src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                        pdfURL
                      )}&embedded=true`}
                      title='PDF Viewer'
                      width='100%'
                      height='600'
                      style={{width: '100%'}}
                    />
                  </p>
                </div>

                <div className='mt-12 d-flex flex-column'>
                  <button
                    onClick={() => {
                      setIsAccepted(true)
                      setShowModal(false)
                    }}
                    className='form-check-label fw-bold btn btn-primary fs-6 mb-5'
                  >
                    {intl.formatMessage({id: 'AUTH.REGISTER.LABEL.AGREE'})}
                  </button>

                  <button
                    onClick={() => {
                      setIsAccepted(false)
                      setShowModal(false)
                    }}
                    className='form-check-label fw-bold btn btn-secondary fs-6 mb-5'
                  >
                    {intl.formatMessage({id: 'AUTH.REGISTER.LABEL.DISAGREE'})}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}

        <div className='d-flex align-items-center justify-content-center mb-3 w-full'>
          <h1 className='text-dark text-center'>
            {intl.formatMessage({
              id: 'AUTH.REGISTER_ACCOUNT.THIRD_STEP.TITLE',
            })}
          </h1>
          {/* <button
            type='button'
            onClick={() => setShowInfoModal(true)}
            className='btn btn-sm btn-primary rounded-circle text-center'
          >
            i
          </button> */}
        </div>

        {/* end::Title */}

        {/* begin::Link */}

        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>

        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {/* {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              {Object.keys(formik.status).map((key) => {
                if (key === 'email' && formik.status[key] === '1')
                  return (
                    <div className='alert-text font-weight-bold'>
                      {intl.formatMessage({id: 'AUTH.REGISTER.DUPLICATED_EMAIL'})}{' '}
                      <Link to='/auth/forgot-password'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.FORGOT_PASSWORD.ERROR'})}
                      </Link>
                      ?
                    </div>
                  )

                if (key === '0') return null

                return <div className='alert-text font-weight-bold'>{formik.status[key]}</div>
              })}
            </div>
          )} */}
      {errorMessage !== '' && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorMessage}</div>
        </div>
      )}

      {/* begin::Form group Business Name */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'PROFILE.FORM.LABEL.EMAIL'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='text'
              value={createAccountValues.email}
              onChange={(e) => changeValue('email', e.target.value)}
              placeholder={intl.formatMessage({id: 'PROFILE.FORM.PLACEHOLDER.EMAIL'})}
              autoComplete='off'
              //{...formik.getFieldProps('businessName')}
              className={clsx(
                'form-control bg-transparent'
                // {
                //   'is-invalid': formik.touched.businessName && formik.errors.businessName,
                // },
                // {
                //   'is-valid': formik.touched.businessName && !formik.errors.businessName,
                // }
              )}
            />
            {/* {formik.touched.businessName && formik.errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Government ID */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              value={createAccountValues.password}
              onChange={(e) => changeValue('password', e.target.value)}
              placeholder={intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD'})}
              autoComplete='off'
              //{...formik.getFieldProps('businessName')}
              className={clsx(
                'form-control bg-transparent'
                // {
                //   'is-invalid': formik.touched.businessName && formik.errors.businessName,
                // },
                // {
                //   'is-valid': formik.touched.businessName && !formik.errors.businessName,
                // }
              )}
            />
            {/* {formik.touched.businessName && formik.errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Government ID */}
      <div className='fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              value={createAccountValues.confirmPassword}
              onChange={(e) => changeValue('confirmPassword', e.target.value)}
              placeholder={intl.formatMessage({id: 'PROFILE.SIGN_IN.FORM.LABEL.PASSWORD'})}
              autoComplete='off'
              //{...formik.getFieldProps('businessName')}
              className={clsx(
                'form-control bg-transparent'
                // {
                //   'is-invalid': formik.touched.businessName && formik.errors.businessName,
                // },
                // {
                //   'is-valid': formik.touched.businessName && !formik.errors.businessName,
                // }
              )}
            />
            {/* {formik.touched.businessName && formik.errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      <div className='d-flex flex-column p-6 gap-2'>
        {verifyMinLength(createAccountValues.password) ? (
          <span className={`text-success`}>
            ✓ {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_CHARACTERS'})}
          </span>
        ) : (
          <span className={`text-danger`}>
            X {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_CHARACTERS'})}
          </span>
        )}

        {verifyLowerAndUpperCase(createAccountValues.password) ? (
          <span className={`text-success`}>
            ✓ {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_UPPER_AND_LOWER_CASE'})}
          </span>
        ) : (
          <span className={`text-danger`}>
            X {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_UPPER_AND_LOWER_CASE'})}
          </span>
        )}

        {verifySpecialChar(createAccountValues.password) ? (
          <span className={'text-success'}>
            ✓ {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_SPECIAL_CHARACTER'})}
          </span>
        ) : (
          <span className={'text-danger'}>
            X {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_SPECIAL_CHARACTER'})}
          </span>
        )}

        {verifyLettersAndNumber(createAccountValues.password) ? (
          <span className={'text-success'}>
            ✓ {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_LETTERS_AND_NUMBERS'})}
          </span>
        ) : (
          <span className={'text-danger'}>
            X {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_LETTERS_AND_NUMBERS'})}
          </span>
        )}

        {createAccountValues.confirmPassword !== '' &&
        createAccountValues.password === createAccountValues.confirmPassword ? (
          <span className={'text-success'}>
            ✓ {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_CONFIRM_PASSWORD'})}
          </span>
        ) : (
          <span className={'text-danger'}>
            X {intl.formatMessage({id: 'AUTH.VERIFY_LOGIN.SUBTITLE_CONFIRM_PASSWORD'})}
          </span>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={isAccepted}
            onClick={() => setIsAccepted(!isAccepted)}
            id='kt_login_toc_agree'
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.formatMessage({id: 'AUTH.REGISTER.LABEL.AGREE'})}{' '}
            <button
              type='button'
              onClick={() => setShowModal(true)}
              className='ms-1 link-primary bg-transparent border-0'
            >
              {intl.formatMessage({id: 'AUTH.REGISTER.LABEL.TERMS'})}
            </button>
            .
          </label>
        </div>
      </div>

      {/* begin::Form group Firstname */}

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='button'
          id='kt_sign_up_submit'
          disabled={
            isAccepted === false ||
            createAccountValues.password === '' ||
            createAccountValues.email === ''
          }
          onClick={() => handleSubmit()}
          className='btn btn-lg btn-primary w-100 mb-5'
          //disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.SUBMIT'})}
          </span>
          {/* {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )} */}
        </button>

        <button
          onClick={() => {
            setCurrentStep(currentStep - 1)
          }}
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
        >
          {intl.formatMessage({id: 'FORM.BUTTON.CANCEL'})}
        </button>
      </div>
      {/* end::Form group */}
    </>
  )
}

export default ThirdStep
