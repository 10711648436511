import axios from 'axios'
import jwt_decode from 'jwt-decode'
import {getCompanyStorage} from './SettingsHelpers'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = '@awniva/auth'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const refreshToken = async (refreshToken: string | undefined) => {
  try {
    const axiosRefresh = axios.create()
    const API_URL = process.env.REACT_APP_API_URL
    const {data} = await axiosRefresh.post(`${API_URL}/v2/auth/refresh`, {
      refreshToken: refreshToken,
    })

    if (data?.httpStatus !== '200') {
      removeAuth()
    } else {
      setAuth({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
      return data
    }

    return data
  } catch (err) {}
}

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.interceptors.request.use(
    async (config: {
      headers: {Authorization: string; Workspace: string; Language: string; Environment: string}
    }) => {
      const auth: any = getAuth()
      if (auth) {
        let currentDate = new Date()
        const decodedToken: any = jwt_decode(auth.accessToken)
        if (decodedToken && decodedToken.exp * 1000 < currentDate.getTime()) {
          const data = await refreshToken(auth.refreshToken)
          if (data && data.accessToken && data.refreshToken) {
            config.headers.Authorization = `Bearer ${data.accessToken}`
          }
        } else if (auth.accessToken) {
          config.headers.Authorization = `Bearer ${auth.accessToken}`
        }
      }

      const idiomas_es = [
        'es',
        'es-AR',
        'es-BO',
        'es-CL',
        'es-CO',
        'es-CR',
        'es-DO',
        'es-EC',
        'es-SV',
        'es-GQ',
        'es-GT',
        'es-HN',
        'es-MX',
        'es-NI',
        'es-PA',
        'es-PY',
        'es-PE',
        'es-PH',
        'es-PR',
        'es-ES',
        'es-US',
        'es-UY',
        'es-VE',
      ]
      const idiomas_en = [
        'en',
        'en-US',
        'en-GB',
        'en-AU',
        'en-CA',
        'en-IN',
        'en-IE',
        'en-MT',
        'en-NZ',
        'en-PH',
        'en-SG',
        'en-ZA',
        'en-ZW',
      ]
      const idiomas_pt = ['pt', 'pt-PT', 'pt-BR', 'pt-AO', 'pt-MZ', 'pt-GW', 'pt-TL']

      let lang = JSON.parse(String(localStorage.getItem(I18N_CONFIG_KEY)))?.selectedLang

      if (!lang) {
        lang = window.navigator.language
      }

      if (idiomas_en.includes(lang)) {
        config.headers.Language = 'english'
      } else if (idiomas_pt.includes(lang)) {
        config.headers.Language = 'portuguese-brazilian'
      } else if (idiomas_es.includes(lang)) {
        config.headers.Language = 'spanish'
      } else {
        config.headers.Language = 'english'
      }

      if (process.env.REACT_APP_SAFETYHUB_ENVIRONMENT) {
        config.headers.Environment = process.env.REACT_APP_SAFETYHUB_ENVIRONMENT
      }

      const settings = getCompanyStorage()
      if (settings && settings.companyPublicKey) {
        config.headers.Workspace = settings.companyPublicKey
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {AUTH_LOCAL_STORAGE_KEY, getAuth, refreshToken, removeAuth, setAuth}
