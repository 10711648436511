/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {listCompany} from '../../../../app/modules/auth/core/_requests'
import {CompanyModel} from '../../../../app/modules/auth/models/CompanyModel'
import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout, saveCompanyStorage, currentFeaturesObject, companyStorage} = useAuth()
  const intl = useIntl()

  const [companyUser, setCompanyUser] = useState([])

  useEffect(() => {
    async function getCompany() {
      const {data} = await listCompany()
      setCompanyUser(data?.result)
    }

    getCompany()
  }, [])

  function setCompany(publicKey: string, name: string, image: string, country: string) {
    const company: CompanyModel = {
      publicKey: publicKey,
      name: name,
      image: image,
      country: country
    }
    saveCompanyStorage({
      companyName: company.name,
      companyPublicKey: company.publicKey,
      companyCountry: company.country,
      companyImage: process.env.REACT_APP_CDN_URL + `${company.publicKey}/company-logo`,
    })
    window.location.replace('/')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                process.env.REACT_APP_CDN_SENSITIVE_URL +
                `${companyStorage?.companyPublicKey}/user-image/${currentUser?.publicKey}`
              }
              onError={({currentTarget}) => {
                currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
              }}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Admin</span>
            </div>
            <span className='fw-bold text-muted fs-8'>
              {currentUser?.email?.length! >= 24
                ? currentUser?.email?.substring(0, 24) + '...'
                : currentUser?.email}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {currentFeaturesObject && currentFeaturesObject.user && (
        <div className='menu-item px-5 my-1'>
          <Link to='/settings/account/' className='menu-link px-5'>
            {intl.formatMessage({id: 'HEADER_USER.MY_PROFILE'})}
          </Link>
        </div>
      )}

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>{intl.formatMessage({id: 'HEADER_USER.WORKSPACE'})}</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-200px py-4'>
          <div className='menu-item px-3'>
            {companyUser?.map((company: any, index) => (
              <a
                onClick={() =>
                  setCompany(
                    company.company_publicKey,
                    company.company_businessName,
                    company.company_image,
                    company.company_country
                  )
                }
                key={index}
                className='menu-link px-5'
              >
                {company.company_businessName}
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      {currentFeaturesObject && currentFeaturesObject.company && (
        <div className='menu-item px-5'>
          <Link to='/settings/company/overview' className='menu-link px-5'>
            {intl.formatMessage({id: 'HEADER_USER.ACCOUNT_SETTINGS'})}
          </Link>
        </div>
      )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER_USER.SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
