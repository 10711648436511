import clsx from 'clsx'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface CreateAccount {
  businessName: string
  governmentId: string
  countryPublicKey: string
  firstName: string
  lastName: string
  userGovernmentId: string
  phone: string
  email: string
  password: string
}

type Props = {
  countryOptions: any
  changeValue: (valueToChange: keyof CreateAccount, data: any) => void
  createAccountValues: any
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  currentStep: number
}

const FirstStep: React.FC<Props> = ({
  countryOptions,
  changeValue,
  createAccountValues,
  setCurrentStep,
  currentStep,
}) => {
  const intl = useIntl()

  const [showInfoModal, setShowInfoModal] = useState(false)

  const brazilOption = countryOptions?.filter((item: any) => item.name === 'Brazil')
  const unitedStatesOption = countryOptions?.filter((item: any) => item.name === 'United States')
  const chileOption = countryOptions?.filter((item: any) => item.name === 'Chile')

  return (
    <>
      {showInfoModal && (
        <>
          <Modal
            id='kt_modal_terms_and_conditions'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-800px h-auto'
            show={showInfoModal}
            keyboard={false}
            backdrop='static'
            className='w-100 h-100 '
          >
            <div className='container-xxl px-5 py-5'>
              <div className='modal-header py-2 d-flex flex-column justify-content-center border-0'>
                <button
                  onClick={() => setShowInfoModal(false)}
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                ></button>
                <h1 className='text-dark mb-3 display-4'>
                  {intl.formatMessage({
                    id: 'AUTH.REGISTER_ACCOUNT.HOW_WE_USE_YOUR_DATA.TEXT',
                  })}
                </h1>

                <div className='d-flex flex-column justify-content-start align-items-start'>
                  <div className='p-6 d-flex flex-column gap-2'>
                    <h1>{intl.formatMessage({id: 'AUTH.REGISTER.LABEL.BUSINESS_NAME'})}</h1>
                    <p>
                      {intl.formatMessage({
                        id: 'AUTH.REGISTER_ACCOUNT.FIST_STEP.BUSINESS_NAME.TEXT',
                      })}
                    </p>
                    <hr className='hr' />
                  </div>

                  <div className='p-6 d-flex flex-column gap-2'>
                    <h1>{intl.formatMessage({id: 'EMPLOYEE.FORM.LABEL.GOVERNMENT_ID'})}</h1>
                    <p>
                      {intl.formatMessage({
                        id: 'AUTH.REGISTER_ACCOUNT.FIST_STEP.GOVERNMENT_ID.TEXT',
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <div className='d-flex align-items-center justify-content-center mb-3 w-full'>
          <h1 className='text-dark text-center'>
            {intl.formatMessage({
              id: 'AUTH.REGISTER_ACCOUNT.FIST_STEP.TITLE',
            })}
          </h1>
          {/* <button
            type='button'
            onClick={() => setShowInfoModal(true)}
            className='btn btn-sm btn-primary rounded-circle text-center'
          >
            i
          </button> */}
        </div>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE'})}
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            {intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE.SIGN_IN'})}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>
          {intl.formatMessage({id: 'AUTH.REGISTER.OR'})}
        </span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {/* {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              {Object.keys(formik.status).map((key) => {
                if (key === 'email' && formik.status[key] === '1')
                  return (
                    <div className='alert-text font-weight-bold'>
                      {intl.formatMessage({id: 'AUTH.REGISTER.DUPLICATED_EMAIL'})}{' '}
                      <Link to='/auth/forgot-password'>
                        {intl.formatMessage({id: 'AUTH.REGISTER.FORGOT_PASSWORD.ERROR'})}
                      </Link>
                      ?
                    </div>
                  )

                if (key === '0') return null

                return <div className='alert-text font-weight-bold'>{formik.status[key]}</div>
              })}
            </div>
          )} */}

      {/* begin::Form group Business Name */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'AUTH.REGISTER.LABEL.BUSINESS_NAME'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='text'
              value={createAccountValues.businessName}
              onChange={(e) => changeValue('businessName', e.target.value)}
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.PLACEHOLDER.BUSINESS_NAME'})}
              autoComplete='off'
              //{...formik.getFieldProps('businessName')}
              className={clsx(
                'form-control bg-transparent'
                // {
                //   'is-invalid': formik.touched.businessName && formik.errors.businessName,
                // },
                // {
                //   'is-valid': formik.touched.businessName && !formik.errors.businessName,
                // }
              )}
            />
            {/* {formik.touched.businessName && formik.errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Government ID */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'EMPLOYEE.FORM.LABEL.GOVERNMENT_ID'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='text'
              value={createAccountValues.governmentId}
              onChange={(e) => changeValue('governmentId', e.target.value)}
              placeholder={intl.formatMessage({id: 'EMPLOYEE.FORM.PLACEHOLDER.GOVERNMENT_ID'})}
              autoComplete='off'
              //{...formik.getFieldProps('businessName')}
              className={clsx(
                'form-control bg-transparent'
                // {
                //   'is-invalid': formik.touched.businessName && formik.errors.businessName,
                // },
                // {
                //   'is-valid': formik.touched.businessName && !formik.errors.businessName,
                // }
              )}
            />
            {/* {formik.touched.businessName && formik.errors.businessName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}

      {/* end::Form group */}

      <div className='mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'MANUFACTURER.MODAL.LABEL.COUNTRY'})}
        </label>

        <div className='d-flex flex-column flex-lg-row align-items-center justify-content-center gap-4'>
          <button
            onClick={() => {
              changeValue('countryPublicKey', unitedStatesOption[0]?.publicKey)
            }}
            style={{
              opacity:
                createAccountValues.countryPublicKey === unitedStatesOption[0]?.publicKey
                  ? '100%'
                  : '40%',
            }}
            type='button'
            className='btn'
          >
            <img
              src={toAbsoluteUrl('/media/flags/united-states.svg')}
              alt=''
              className='align-self-end h-50px rounded'
            />
          </button>

          <button
            onClick={() => {
              changeValue('countryPublicKey', chileOption[0]?.publicKey)
            }}
            style={{
              opacity:
                createAccountValues.countryPublicKey === chileOption[0]?.publicKey ? '100%' : '40%',
            }}
            type='button'
            className='btn'
          >
            <img
              src={toAbsoluteUrl('/media/flags/spain.svg')}
              alt=''
              className='align-self-end h-50px rounded'
            />
          </button>

          <button
            onClick={() => {
              changeValue('countryPublicKey', brazilOption[0]?.publicKey)
            }}
            style={{
              opacity:
                createAccountValues.countryPublicKey === brazilOption[0]?.publicKey
                  ? '100%'
                  : '40%',
            }}
            type='button'
            className='btn'
          >
            <img
              src={toAbsoluteUrl('/media/flags/brazil.svg')}
              alt=''
              className='align-self-end h-50px rounded'
            />
          </button>
        </div>
      </div>

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='button'
          onClick={() => {
            if (createAccountValues.businessName !== '') {
              if (createAccountValues.governmentId !== '') {
                if (createAccountValues.countryPublicKey !== '') {
                  setCurrentStep(currentStep + 1)
                }
              }
            }
          }}
          disabled={
            createAccountValues.businessName === '' ||
            createAccountValues.governmentId === '' ||
            createAccountValues.countryPublicKey === ''
          }
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          //disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'FORM.STEP.BUTTON.CONTINUE'})}
          </span>
          {/* {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )} */}
        </button>

        {/* <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
        >
          {intl.formatMessage({id: 'AUTH.REGISTER.BUTTON.CANCEL'})}
        </button> */}
      </div>
      {/* end::Form group */}
    </>
  )
}

export default FirstStep
