/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {HeaderLabeling} from './HeaderLabeling'
import {Topbar} from './Topbar'
import {DefaultTitle} from './page-title/DefaultTitle'

const getBreakpoints = (): any => {
  const mediaQueries: Record<string, MediaQueryList> = {
    xs: window.matchMedia('(min-width: 376px)'),
    sm: window.matchMedia('(min-width: 576px)'),
    md: window.matchMedia('(min-width: 768px)'),
    lg: window.matchMedia('(min-width: 992px)'),
    xl: window.matchMedia('(min-width: 1200px)'),
  }

  const keys = Object.keys(mediaQueries) as Array<keyof typeof mediaQueries>
  const values = keys.map((key) => mediaQueries[key].matches)

  return Object.fromEntries(keys.map((_, i) => [keys[i], values[i]])) as any
}

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const navigate = useNavigate()
  const [isDown, setIsDown] = useState(false)

  const {currentFeatures, currentUser} = useAuth()

  const filterFeatures = currentFeatures?.filter((o: any) => o.feature === 'manufacturerLabeling')

  const [breakpoints, setBreakpoints] = useState<any>(getBreakpoints())

  useEffect(() => {
    const handleResize = () => setBreakpoints(getBreakpoints())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleScroll = (): void => {
    const headerDiv = document.getElementById('kt_header')
    if (headerDiv) {
      const headerHeight = headerDiv.offsetHeight
      if (window.pageYOffset > headerHeight) {
        if (breakpoints.md === false) {
          setIsDown(true)
        }
      } else {
        setIsDown(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), `align-items-stretch`)}
      //style={{zIndex: '99999'}}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          `d-flex align-items-center position-relative ${isDown && 'bg-gray-100'}`
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        {currentUser && (
          <div
            className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
            title='Show aside menu'
          >
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
            </div>
          </div>
        )}

        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
          <div className='cursor-pointer' onClick={() => navigate('/dashboard')}>
            <img
              alt='Logo'
              src={`${process.env.REACT_APP_CDN_URL}safetyhub/assets/logo_white.svg`}
              className='logo-default h-40px'
            />
            <img
              alt='Logo'
              src={`${process.env.REACT_APP_CDN_URL}safetyhub/assets/logo-safetyhub.svg`}
              className='logo-sticky h-40px'
            />
          </div>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {filterFeatures?.length === 0 ? <Header /> : <HeaderLabeling />}
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
